import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTen() {
    console.log("Fun fact: the dish soap SOS jingle is to the rhythm of SOS in morse code ... --- ...")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>-. --- / -. . . -.. / ..-. --- .-. / .-. . -- --- .-. ... . --..-- / - .... . / .- -. ... .-- . .-. / .. ... / -.. .- ... .... .. -. --.</p>
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'dashing'
                            hint = {<div>Need me to send you a telegram? Figure it out.</div>}
                            nextLevel = '../levels/level11'
                            />
        </div>
    )
};