import levelFiveImage from '../../constants/imageFiles/levelFiveImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelFive() {
    console.log("The answer is: puppies")
    console.log("Note: More direct hints are here on all levels, in case in app hints aren't helpful")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>This level is best experienced on a desktop computer.</p>
                    <p>This woman is sad because she can't find the answer.</p>
                    <p>Can you console her?</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelFiveImage}
                        alt="A crying woman."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'puppies'
                            hint = {<div>In chrome, you can optionally command her "i"s not to cry. A refresher might help too.</div>}
                            nextLevel = '../levels/level5b'
                            />
        </div>
    )
};