import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelEight() {
    console.log("Real coders just read the binary, but you can probably convert it.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>01000001 01110111 01110111 01110111 00101100 00100000 01001000 01100101 01111000 00101110 00100000 01010100 01101000 01100101 00100000 01100001 01101110 01110011 01110111 01100101 01110010 00100000 01101001 01110011 00100000 00110100 00111000 00100000 00110110 00110001 00100000 00110110 00110011 00100000 00110110 01000010 00100000 00110110 00110101 00100000 00110111 00110010</p>
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'hacker'
                            hint = {<div>ASCII no more questions.</div>}
                            nextLevel = '../levels/level9'
                            />
        </div>
    )
};