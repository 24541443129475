import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelFour() {
    console.log("It's much better than the previous prototype, Z-26")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>20 8 5 1 14 19 23 5 18 9 19 1 12 16 8 1 2 5 20</p>
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'alphabet'
                            hint = {<div>A-1 is a steak sauce</div>}
                            nextLevel = '../levels/level5'
                            />
        </div>
    )
};