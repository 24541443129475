import levelTwoeImage from '../../constants/imageFiles/levelTwoeImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwoe() {
    console.log("Maybe you can find the answer back in the lab.")
    return (
        <div>


            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwoeImage}
                        alt="A boy wearing a white lab coat with purple gloves."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'dexter'
                            hint = {<div>Before he grew up to be a benevolent mass murderer.</div>}
                            nextLevel = '../levels/level2f'
                            />
        </div>
    )
};