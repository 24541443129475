import levelTwobImage from '../../constants/imageFiles/levelTwobImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwob() {
    console.log("I asked, 'What's up?', Doc")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwobImage}
                        alt="A rabbit eating a carrot"
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox
                            correctAnswer = 'bugs'
                            hint = {<div>What's up?</div>}
                            nextLevel = '../levels/level2c'
                             />
        </div>
    )
};