import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';
import { useState, useEffect } from 'react';
import Willow2 from '../../constants/imageFiles/Willow2.jpeg';
import { Navigate } from 'react-router-dom';

export default function LevelFifteene() {
    console.log("Tyler is Simon");
    const [answerTimeOutReached, setAnswerTimeoutReached] = useState(0)

   useEffect(()=>{const timer = setTimeout(()=> {
    setAnswerTimeoutReached(1);
}, 20000); return () => clearTimeout(timer);
   });

   if (answerTimeOutReached===0){
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Good Job.</p>
                    <p>Simon says that Tyler is now Simon</p>
                    <p>Tyler says the answer is "hounds" </p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={Willow2}
                        alt="Both of the best dogs."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'hounds'
                            hint = {<div>Tyler Says</div>}
                            nextLevel = '../levels/Level15f'
                            />
        </div>
    )
   }
   else return <Navigate to='../levels/Level15SimonSaid'/>
};