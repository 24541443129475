import { Grid } from '@mui/material'
import { Link } from "react-router-dom"

function LevelSelector(){
    return(
        <div>
            <h1>Select Level:</h1>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={2} borderColor='white' border={1}><Link to={"../"}>
                    <p>1</p></Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level2"}>
                    <p>2</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level3"}>
                    <p>3</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level4"}>
                    <p>4</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level5"}>
                    <p>5</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level6"}>
                    <p>6</p>
                    </Link>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level7"}>
                    <p>7</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level8"}>
                    <p>8</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level9"}>
                    <p>9</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level10"}>
                    <p>10</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level11"}>
                    <p>11</p>
                    </Link>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level12"}>
                    <p>12</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level13"}>
                    <p>13</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level14"}>
                    <p>14</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level15"}>
                    <p>15</p>
                    </Link>
                </Grid>
                <Grid item xs={2} borderColor='white' border={1}>
                <Link to={"../levels/level16"}>
                    <p>16</p>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}

export default LevelSelector;