import './App.css';
// import { useState } from 'react';
import * as React from "react";
import {
  RouterProvider,
} from "react-router-dom";
import appRouter from './routes/appRouter';
// import { Outlet } from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  fontSize: '1.5rem',
  palette: {
    mode: 'dark',
  },
});


const router = appRouter;

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
    <CssBaseline />        
      <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
