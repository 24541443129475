import React, { useEffect } from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelThree() {

    const [humanPlayer, setHumanPlayer] = useState("X");
    const [computerPlayer, setComputerPlayer] = useState("O");
    const [xBgColor, setXBgColor] = useState("red");
    const [oBgColor, setOBgColor] = useState("white");
    const [grid, setGrid] = useState(["", "", "", "", "", "", "", "", ""]);
    const [winBackground, setWinBackground] = useState([{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" }])
    const [answerTimeOutReached, setAnswerTimeoutReached] = useState(0)

   useEffect(()=>{const timer = setTimeout(()=> {
    setAnswerTimeoutReached(1);
}, 60000); return () => clearTimeout(timer);
   }, [grid, humanPlayer]);


    useEffect(function recolorPlayerSelection() {
        if (humanPlayer === "X") {
            setComputerPlayer("O");
            setXBgColor("red");
            setOBgColor("white");
        }
        else {
            setXBgColor("white");
            setOBgColor("red");
            setComputerPlayer("X")
        }
    }, [humanPlayer]);

    useEffect(function computerFirstMove() {
        console.log("Maybe re-watch War Games. It should only take a minute to beat this game.");
        if (computerPlayer === "X") {
            var newGrid = ["", "", "", "", "X", "", "", "", ""]
            setGrid(newGrid);
        }
    }, [computerPlayer]);


    function changePlayer(selectedPlayer) {
        console.log("Maybe re-watch War Games. It should only take a minute to beat this game.");
        setGrid(["", "", "", "", "", "", "", "", ""]);
        setHumanPlayer(selectedPlayer);
    }


    function squareSelect(gridNum) {
        if (grid[gridNum] === "") {

            var newGrid = [...grid];
            var computerMove;
            var xCount = 0;

            newGrid[gridNum] = humanPlayer;
            setGrid(newGrid)

            newGrid.forEach(x => {
                if (x === "X") { xCount++ }
            })
    
            if ((xCount > 4 )) {
               return setTimeout(function () {
                    playAgain();
                }, 1500);
            };

            computerMove = offense();
            if (!computerMove) {
                computerMove = defense();
            }

            if (computerMove === undefined) {
                if (newGrid[4] === "") {
                    computerMove = 4;
                }
                else if (newGrid[4] === humanPlayer && newGrid[0] === "") {
                    computerMove = 0;
                }
                else if (newGrid[0] === computerPlayer && newGrid[4] === humanPlayer && newGrid[8] === humanPlayer && newGrid[2] === "") {
                    computerMove = 2;
                }
                else if (newGrid[7] === humanPlayer && newGrid[4] === computerPlayer && newGrid[0] === humanPlayer && newGrid[6] === "") {
                    computerMove = 6;
                }
                else if (newGrid[1] === humanPlayer && newGrid[4] === computerPlayer && newGrid[7] === humanPlayer && newGrid[2] === "") {
                    computerMove = 2;
                }
                else if (newGrid[1] === humanPlayer && newGrid[4] === computerPlayer && newGrid[6] === humanPlayer && newGrid[0] === "") {
                    computerMove = 0;
                }
                else if (newGrid[1] === humanPlayer && newGrid[4] === computerPlayer && newGrid[8] === humanPlayer && newGrid[2] === "") {
                    computerMove = 2;
                }
                else if (newGrid[2] === humanPlayer && newGrid[4] === computerPlayer && newGrid[7] === humanPlayer && newGrid[8] === "") {
                    computerMove = 8;
                }
            }

            if (computerMove === undefined) {
                if ((newGrid[gridNum + 1] === "") && ((gridNum + 1) % 3 !== "")) {
                    computerMove = gridNum + 1;
                }
                else if (newGrid[gridNum - 1] === "" && ((gridNum - 1) % 3 !== computerPlayer)) {
                    computerMove = (gridNum - 1);
                }
                else if (gridNum > 2 && (newGrid[gridNum - 3] === "")) {
                    computerMove = (gridNum - 3);
                }
                else if (gridNum < 6 && newGrid[gridNum + 3] === "") {
                    computerMove = (gridNum + 3);
                }
                else {
                    for (var x in newGrid) {
                        if (newGrid[x] === "") {
                            computerMove = x;
                        }
                    }
                }
            };

            newGrid[computerMove] = computerPlayer;
            setGrid(newGrid);

            function verticalCheck() {
                for (var i = 0; i < 3; i++) {
                    var newBackgroundV = winBackground;
                    if (newGrid[i] === newGrid[i + 3] && newGrid[i] === newGrid[i + 6] && newGrid[i] !== "") {
                        newBackgroundV[i] = { background: "red", color: "white" }
                        newBackgroundV[i+3] = { background: "red", color: "white" }
                        newBackgroundV[i+6] = { background: "red", color: "white" }
                        setWinBackground(newBackgroundV)
                        setTimeout(function () {
                            playAgain();
                        }, 1500);
                    }
                }
            };
    
            function horizontalCheck() {
                var newBackgroundH = winBackground;
                for (var j = 0; j <= 7; j += 3) {
                    if ((newGrid[j] === newGrid[j + 1]) && newGrid[j] === newGrid[j + 2] && newGrid[j] !== "") {
                        newBackgroundH[j] = { background: "red", color: "white" }
                        newBackgroundH[j+1] = { background: "red", color: "white" }
                        newBackgroundH[j+2] = { background: "red", color: "white" }
                        setWinBackground(newBackgroundH)
                        setTimeout(function () {
                            playAgain();
                        }, 1500);
                    }
                }
            };
    
            function diagonalCheck() {
                var newBackgroundD = winBackground;
                if (newGrid[0] === newGrid[4] && newGrid[0] === newGrid[8] && newGrid[0] !== "") {
                    newBackgroundD[0] = { background: "red", color: "white" }
                    newBackgroundD[4] = { background: "red", color: "white" }
                    newBackgroundD[8] = { background: "red", color: "white" }
                    setWinBackground(newBackgroundD)
                    setTimeout(function () {
                        playAgain();
                    }, 1500);
                }
                else if (newGrid[2] === newGrid[4] && newGrid[2] === newGrid[6] && newGrid[2] !== "") {
                    newBackgroundD[2] = { background: "red", color: "white" }
                    newBackgroundD[4] = { background: "red", color: "white" }
                    newBackgroundD[6] = { background: "red", color: "white" }
                    setWinBackground(newBackgroundD)
                    setTimeout(function () {
                        playAgain();
                    }, 1500);
                }
                
            };
    
            function playAgain() {
               setWinBackground([{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" },{ background: "black", color: "white" }])
               if (humanPlayer==="X") {setGrid(["", "", "", "", "", "", "", "", ""]);}
               else setGrid(["", "", "", "", "X", "", "", "", ""])

            }
    
            verticalCheck();
            horizontalCheck();
            diagonalCheck();

            if ((xCount > 3 && humanPlayer==="O" )) {
                return setTimeout(function () {
                     playAgain();
                 }, 1500);
             };
        }

        function offense() {
            //if you can win, win.
            for (var m = 0; m < newGrid.length; m += 3) {
                if (newGrid[m] === computerPlayer && newGrid[m + 1] === computerPlayer && newGrid[m + 2] === "") {
                    return (m + 2);
                }
                if (newGrid[m] === computerPlayer && newGrid[m + 2] === computerPlayer && newGrid[m + 1] === "") {
                    return (m + 1);
                }
                if (newGrid[m + 1] === computerPlayer && newGrid[m + 2] === computerPlayer && newGrid[m] === "") {
                    return (m);
    
                }
            }
            for (var n = 0; n < 3; n++) {
                if (newGrid[n] === computerPlayer && newGrid[n + 3] === computerPlayer && newGrid[n + 6] === "") {
                    return (n + 6);
                }
                if (newGrid[n] === computerPlayer && newGrid[n + 6] === computerPlayer && newGrid[n + 3] === "") {
                    return (n + 3);
                }
                if (newGrid[n + 3] === computerPlayer && newGrid[n + 6] === computerPlayer && newGrid[n] === "") {
                    return (n);
                }
            }
            if (newGrid[0] === computerPlayer && newGrid[4] === computerPlayer && newGrid[8] === "") {
                return (8);
            }
            if (newGrid[0] === computerPlayer && newGrid[8] === computerPlayer && newGrid[4] === "") {
                return (4);
            }
            if (newGrid[4] === computerPlayer && newGrid[8] === computerPlayer && newGrid[0] === "") {
                return (0);
            }
            if (newGrid[2] === computerPlayer && newGrid[4] === computerPlayer && newGrid[6] === "") {
                return (6);
            }
            if (newGrid[2] === computerPlayer && newGrid[6] === computerPlayer && newGrid[4] === "") {
                return (4);
            }
            if (newGrid[6] === computerPlayer && newGrid[4] === computerPlayer && newGrid[2] === "") {
                return (2);
            }
        }

        function defense() {
            //if you can't win, block them

            //check horizontal
            for (var k = 0; k < newGrid.length; k += 3) {
                var countOfHuman =0
                if (newGrid[k] === humanPlayer){countOfHuman++}
                if (newGrid[k+1] === humanPlayer){countOfHuman++}
                if (newGrid[k+2] === humanPlayer){countOfHuman++}
                if (countOfHuman===2) {
                    if (newGrid[k] === "") {
                        return (k);
                    }
                    else if (newGrid[k + 1] === "") {
                        return (k + 1);
                    }
                    else if (newGrid[k + 2] === "") {
                        return (k + 2);
                    }
        
                }
            }
            //check vertical
            for (var l = 0; l <= 3; l++) {
                var countOfHumanV =0
                if (newGrid[l] === humanPlayer){countOfHumanV++}
                if (newGrid[l+3] === humanPlayer){countOfHumanV++}
                if (newGrid[l+6] === humanPlayer){countOfHumanV++}
                if (countOfHumanV===2) {
                    if (newGrid[l] === "") {
                        return (l);
                    }
                    else if (newGrid[l + 3] === "") {
                        return (l + 3);
                    }
                    else if (newGrid[l + 6] === "") {
                        return (l + 6);
                    }
                }
            }
        
            //check diagonal
            if (newGrid[0] === humanPlayer && newGrid[4] === humanPlayer && newGrid[8] === "") {
                return (8);
            }
            if (newGrid[0] === humanPlayer && newGrid[8] === humanPlayer && newGrid[4] === "") {
                return (4);
            }
            if (newGrid[4] === humanPlayer && newGrid[8] === humanPlayer && newGrid[0] === "") {
                return (0);
            }
            if (newGrid[2] === humanPlayer && newGrid[4] === humanPlayer && newGrid[6] === "") {
                return (6);
            }
            if (newGrid[2] === humanPlayer && newGrid[6] === humanPlayer && newGrid[4] === "") {
                return (4);
            }
            if (newGrid[6] === humanPlayer && newGrid[4] === humanPlayer && newGrid[2] === "") {
                return (2);
            }
        
        }
    
    };


    if (answerTimeOutReached===0){

    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Win.</p>
                    <p>Player Select:</p>
                </Grid>
            </Grid>
            <Grid container alignItems='center' justifyContent="center">
                <Grid item xs={1} border={1} onClick={() => changePlayer("X")} style={{ background: xBgColor, color: "black" }}>X</Grid>
                <Grid item xs={1} border={1} onClick={() => changePlayer("O")} style={{ background: oBgColor, color: "black" }}>O</Grid>
            </Grid>
            <br></br>
            <Grid container alignItems='center' justifyContent="center">
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(0)} style={winBackground[0]}>{grid[0]}</Grid>
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(1)} style={winBackground[1]}>{grid[1]}</Grid>
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(2)} style={winBackground[2]}>{grid[2]}</Grid>
            </Grid>
            <Grid container alignItems='center' justifyContent="center">
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(3)} style={winBackground[3]}>{grid[3]}</Grid>
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(4)} style={winBackground[4]}>{grid[4]}</Grid>
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(5)} style={winBackground[5]}>{grid[5]}</Grid>
            </Grid>
            <Grid container alignItems='center' justifyContent="center">
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(6)} style={winBackground[6]}>{grid[6]}</Grid>
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(7)} style={winBackground[7]}>{grid[7]}</Grid>
                <Grid item xs={3} border={1} height={'100px'} container="true" alignItems="center" justifyContent="center" onClick={() => squareSelect(8)} style={winBackground[8]}>{grid[8]}</Grid>
            </Grid>


            <br></br>
            < AnswerBox 
            correctAnswer = 'the only winning move is not to play'
            hint = {<div>You can win in about a minute if you know how to play a game of war.</div>}
            nextLevel = '../levels/level4'
            />
        </div >
    )
    }
    else return(
        <div>
                <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>The Anwer Is:</p>
                    <p>the only winning move is not to play</p>
                </Grid>
            </Grid>
                        <br></br>
            < AnswerBox 
                            correctAnswer = 'the only winning move is not to play'
                            hint = {<div>You can win in about a minute if you know how to play a game of war.</div>}
                            nextLevel = '../levels/level4'
                            />
        </div >
    )
};