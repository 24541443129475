import { Grid, Typography } from '@mui/material'
import { useState } from 'react';
import AnswerBox from '../AnswerBox';
import SimonSound1 from '../../constants/audioFiles/simonSound1.mp3';
import SimonSound2 from '../../constants/audioFiles/simonSound2.mp3';
import SimonSound3 from '../../constants/audioFiles/simonSound3.mp3';
import SimonSound4 from '../../constants/audioFiles/simonSound4.mp3';
import SimonGameOver from '../../constants/audioFiles/simonGameOver.wav';
import { useRef } from 'react';

export default function LevelFourteen() {

    const originalColor = ["#005500", "#990000", "#CCCC00", "#000099"]
    const altColor = ["#00CC00", "#FF0000", "#FFFF00", "#0000FF"];
    const audioSrc = [SimonSound1, SimonSound2, SimonSound3, SimonSound4, SimonGameOver]
    const [score, setScore] = useState(0);
    const [buttonColor, setButtonColor] = useState(originalColor);
    const [answer, setAnswer] = useState("You can keep playing past 14. The answer will persist here.")
    var goal = useRef(0);
    var userClicks = useRef(0);

    function newGame() {
        console.log("Really no hints here. Get 14, get the answer.")
        setScore(0);
        goal.current = [];
        userClicks.current = [];
        addOne();
    }

    function addOne() {
        goal.current.push(Math.floor((Math.random() * 10) % 4));
        cycle();
    }

    async function cycle() {
        for (var i = 0; i < goal.current.length; i++) {
            if (goal.current.length !== 1){
                await sleepTimeout(500);
                blink(goal.current[i])
            }
            else blink(goal.current[i]);
        }
    };

   async function blink(buttonToBlink) {
        var newButtonColorArray = buttonColor.map((c, i) => {
            if (i === buttonToBlink) {
                return altColor[buttonToBlink];
            }
            else return c;
        });
        setButtonColor(newButtonColorArray);
        var audio = new Audio(audioSrc[buttonToBlink]);
        audio.play();

        await sleepTimeout(250);
        
        var returnButtonColorArray = buttonColor.map((c, i) => {
                if (i === buttonToBlink) {
                    return originalColor[buttonToBlink];
                }
                else return c;
            });
            setButtonColor(returnButtonColorArray);
    };

   async function userClicked(buttonClicked){
        if (goal.current[userClicks.current.length] === buttonClicked){
            userClicks.current.push(buttonClicked);
            blink(buttonClicked);
            if(userClicks.current.length===goal.current.length){
                if (score === 13){
                    setAnswer("The answer is working memory");
                }
                setScore(score+1);
                userClicks.current=[];
                await sleepTimeout(500);
                addOne();
            }
        }
        else {
            var gameOverAudio = new Audio(audioSrc[4]);
            gameOverAudio.play();
            setScore(0);
            goal.current = [];
            userClicks.current = [];
        }

    }

    function sleepTimeout(interval) {
        return new Promise((resolve) => {
          setTimeout(resolve, interval);
        });
      };
    
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Level 14</p>
                    <p>No tricks this time. You have to get 14.</p>
                    <p>{answer}</p>
                    <p>Current Score: {score}</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs={2} border={1} onClick={() => newGame()}>
                    <Typography>Play</Typography>
                </Grid>
            </Grid>
            <br></br>

            <Grid container spacing={1} alignItems="stretch" justifyContent="center">
                <Grid item xs={4} sx={styles.greenButton} backgroundColor={buttonColor[0]} onClick={() => userClicked(0)}><></>
                </Grid>
                <Grid item xs={4} sx={styles.redButton} backgroundColor={buttonColor[1]} onClick={() => userClicked(1)}><></>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs={4} sx={styles.yellowButton} backgroundColor={buttonColor[2]} onClick={() => userClicked(2)}><></>
                </Grid>
                <Grid item xs={4} sx={styles.blueButton} backgroundColor={buttonColor[3]} onClick={() => userClicked(3)}><></>
                </Grid>
            </Grid>


            <br></br>
            < AnswerBox 
                            correctAnswer = 'working memory'
                            hint = {<div>There's really no hints or tricks. Get 14 in a row, and it displays the answer.</div>}
                            nextLevel = '../levels/level15'
                            />
        </div>
    )
};

var styles = {
    greenButton: {
        borderRadius: "100% 0% 0% 0%",
        minHeight: "33vh",
        marginRight: "7px",
        marginBottom: "10px"

    },
    redButton: {
        borderRadius: "0% 100% 0% 0%",
        minHeight: "33vh",
        marginLeft: "7px",
        marginBottom: "10px"
    },
    yellowButton: {
        borderRadius: "0% 0% 0% 100%",
        minHeight: "33vh",
        marginRight: "7px",
        marginTop: "10px"
    },
    blueButton: {
        borderRadius: "0% 0% 100% 0%",
        minHeight: "33vh",
        marginLeft: "7px",
        marginTop: "10px"
    }
}
