import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
  
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Did you think this was a riddle game from the 90s? Put your answers in the answer box.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    );
  }