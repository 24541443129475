import { createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
import LevelOne from '../components/levels/Level1';
import LevelSelector from '../components/LevelSelector';
import LastLevel from '../components/levels/LastLevel';
import About from '../components/About';
import ErrorPage from '../components/ErrorPage';
import Navbar from '../components/navbar/Navbar';
import RootLayout from '../components/RootLayout';
import LevelTwo from '../components/levels/Level2';
import LevelTwob from '../components/levels/Level2b';
import LevelTwoc from '../components/levels/Level2c';
import LevelTwod from '../components/levels/Level2d';
import LevelTwoe from '../components/levels/Level2e';
import LevelTwof from '../components/levels/Level2f';
import LevelTwog from '../components/levels/Level2g';
import LevelTwoh from '../components/levels/Level2h';
import LevelTwoi from '../components/levels/Level2i';
import LevelTwoj from '../components/levels/Level2j';
import LevelTwok from '../components/levels/Level2k';
import LevelTwol from '../components/levels/Level2l';
import LevelThree from '../components/levels/Level3';
import LevelFour from '../components/levels/Level4';
import LevelFive from '../components/levels/Level5';
import LevelFiveb from '../components/levels/Level5b';
import LevelFivec from '../components/levels/Level5c';
import LevelFived from '../components/levels/Level5d';
import LevelFivee from '../components/levels/Level5e';
import LevelSix from '../components/levels/Level6';
import LevelSevene from '../components/levels/Level7e';
import LevelSevend from '../components/levels/Level7d';
import LevelSevenc from '../components/levels/Level7c';
import LevelSevenb from '../components/levels/Level7b';
import LevelSeven from '../components/levels/Level7';
import LevelEight from '../components/levels/Level8';
import LevelNine from '../components/levels/Level9';
import LevelTen from '../components/levels/Level10';
import LevelEleven from '../components/levels/Level11';
import LevelTwelve from '../components/levels/Level12';
import LevelThirteen from '../components/levels/Level13';
import LevelFourteen from '../components/levels/Level14';
import LevelFifteen from '../components/levels/Level15';
import LevelFifteenb from '../components/levels/Level15b';
import LevelFifteenc from '../components/levels/Level15c';
import LevelFifteend from '../components/levels/Level15d';
import LevelFifteene from '../components/levels/Level15e';
import LevelFifteenf from '../components/levels/Level15f';
import LevelFifteeng from '../components/levels/Level15g';
import LevelFifteenSimonDidntSay from '../components/levels/Level15SimonDidntSay';
import LevelFifteenSimonSaid from '../components/levels/Level15SimonSaid';
import LevelSixteen from '../components/levels/Level16';
import LevelSixteenb from '../components/levels/Level16b';
import LevelSixteenc from '../components/levels/Level16c';
import LevelSixteend from '../components/levels/Level16d';
import LevelSixteene from '../components/levels/Level16e';


const appRouter = createBrowserRouter(createRoutesFromElements(
    <Route exact path="/" element={ < RootLayout /> } errorElement={ < ErrorPage />} >
      <Route index element={<LevelOne />} />
      <Route path="about" element={ < About /> } errorElement={ < ErrorPage />} />
      <Route path="levelselector" element={ <LevelSelector/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2l" element={ <LevelTwol/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2k" element={ <LevelTwok/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2j" element={ <LevelTwoj/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2i" element={ <LevelTwoi/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2h" element={ <LevelTwoh/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2g" element={ <LevelTwog/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2f" element={ <LevelTwof/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2e" element={ <LevelTwoe/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2d" element={ <LevelTwod/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2c" element={ <LevelTwoc/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2b" element={ <LevelTwob/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level2" element={ <LevelTwo/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level3" element={ <LevelThree/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level4" element={ <LevelFour/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level5e" element={ <LevelFivee/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level5d" element={ <LevelFived/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level5c" element={ <LevelFivec/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level5b" element={ <LevelFiveb/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level5" element={ <LevelFive/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level6" element={ <LevelSix/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level7e" element={ <LevelSevene/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level7d" element={ <LevelSevend/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level7c" element={ <LevelSevenc/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level7b" element={ <LevelSevenb/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level7" element={ <LevelSeven/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level8" element={ <LevelEight/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level9" element={ <LevelNine/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level10" element={ <LevelTen/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level11" element={ <LevelEleven/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level12" element={ <LevelTwelve/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level13" element={ <LevelThirteen/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level14" element={ <LevelFourteen/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15SimonDidntSay" element={ <LevelFifteenSimonDidntSay/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15SimonSaid" element={ <LevelFifteenSimonSaid/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15b" element={ <LevelFifteenb/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15c" element={ <LevelFifteenc/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15d" element={ <LevelFifteend/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15e" element={ <LevelFifteene/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15f" element={ <LevelFifteenf/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15g" element={ <LevelFifteeng/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level15" element={ <LevelFifteen/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level16" element={ <LevelSixteen/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level16b" element={ <LevelSixteenb/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level16c" element={ <LevelSixteenc/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level16d" element={ <LevelSixteend/> } errorElement={ < ErrorPage />} />
      <Route path="levels/level16e" element={ <LevelSixteene/> } errorElement={ < ErrorPage />} />
      <Route path="levels/lastlevel" element={ <LastLevel/> } errorElement={ < ErrorPage />} />
      <Route path="levels/navbar" element={ <Navbar/> } errorElement={ < ErrorPage />} />
    </Route>
  ));
  
  export default appRouter;