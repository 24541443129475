import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';
import { useState, useEffect } from 'react';
import Willow3 from '../../constants/imageFiles/Willow3.jpg';
import { Navigate } from 'react-router-dom';

export default function LevelFifteeng() {
    console.log("Simon's not a liar until Tyler says. Once he's a liar, Charlie is Simon is false. Simon lied when he said he lied before");
    const [answerTimeOutReached, setAnswerTimeoutReached] = useState(0)

   useEffect(()=>{const timer = setTimeout(()=> {
    setAnswerTimeoutReached(1);
}, 60000); return () => clearTimeout(timer);
   });

   if (answerTimeOutReached===0){
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Charlie, Tyler and Simon say the timer is now 60 seconds</p>
                    <p>Charlie says the answer is "arf"</p>
                    <p>Simon says the answer is "bark"</p>
                    <p>Tyler says that Simon is a liar</p>
                    <p>Tyler says that Simon is Tyler</p>
                    <p>Simon says Charlie is Simon</p>
                    <p>Charlie says that Tyler is Charlie</p>
                    <p>Tyler says the answer is "yip"</p>
                    <p>Tyler says that Charlie is Simon</p>
                    <p>Simon says Tyler is not Simon</p>
                    <p>Charlie says Tyler is Simon</p>
                    <p>Tyler says that Charlie is Tyler</p>
                    <p>Simon says Tyler is Charlie</p>
                    <p>Charlie says Simon is Charlie</p>
                    <p>Tyler says Charlie is not Simon</p>
                    <p>Simon says, I lied before. The answer is "woof"</p>
                    <p>Tyler and Charlie both say they didn't lie before, and their answers are correct</p>

                    
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={Willow3}
                        alt="Both of the best dogs."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'bark'
                            hint = {<div>Who Says?</div>}
                            nextLevel = '../levels/Level16'
                            />
        </div>
    )
   }
   else return <Navigate to='../levels/Level15SimonSaid'/>
};