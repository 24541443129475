import levelElevenImage from '../../constants/imageFiles/levelElevenImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelEleven() {
    console.log("N, A, T, and O are letters of the alphabet. The rest is noise.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>They danced the tango on the hotel's veranda. He could feel every step, every note, every sound an echo of her radiance. He felt like an alpha that November, dancing on a veranda with the one he loved at a haven nestled in the Sierra Madres. Maybe it was the whiskey going to his head or that echo catching his heart off guard but he began to feel like Don Quixote or Romeo, like he could build a monument greater than the Taj Mahal in India. But here he was, nestled in the Sierras with the one he loved, dancing the tango in his cleanly pressed uniform on a starry november night, hearing the echo of her song in his heart.</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelElevenImage}
                        alt="A man and a woman dancing the Tango on a hotel vernada with mountains in the distance."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'tune'
                            hint = {<div>Need Another Tip, Oscar?</div>}
                            nextLevel = '../levels/level12'
                            />
        </div>
    )
};