import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';
import { useState, useEffect } from 'react';
import chillinDogs from '../../constants/imageFiles/ChillinDogs.jpeg';
import { Navigate } from 'react-router-dom';

export default function LevelFifteend() {
    console.log("Sirnon isn't Simon.");
    const [answerTimeOutReached, setAnswerTimeoutReached] = useState(0)

   useEffect(()=>{const timer = setTimeout(()=> {
    setAnswerTimeoutReached(1);
}, 20000); return () => clearTimeout(timer);
   });

   if (answerTimeOutReached===0){
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Thanks for acknowledging the superiority of my dogs.</p>
                    <p>Sirnon says, say "Good Dogs"</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={chillinDogs}
                        alt="Both of the best dogs."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'Good dogs'
                            hint = {<div>Read carefully</div>}
                            nextLevel = '../levels/Level15SimonDidntSay'
                            />
        </div>
    )
   }
   else return <Navigate to='../levels/Level15e'/>
};