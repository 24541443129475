import levelSixteencImage from '../../constants/imageFiles/LevelSixteenc.png';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSixteenb() {
    console.log("Why stop now?")
    console.log("This is the next line")
    return (
        <div>
            <br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSixteencImage}
                        alt="A person speaking. In the speech bubble is a beautiful landscape."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = {'there\'s a land that I heard of'}
                            hint = {<div>I come from the land down under</div>}
                            nextLevel = '../levels/level16d'
                            />
        </div>
    )
};