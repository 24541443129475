import { Grid } from '@mui/material'

export default function LevelFivee() {
    console.log("Hi")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>The answer to Level 5d is victory</p>
                    <p>I think you know how to find your way back.</p>
                </Grid>
            </Grid>

        </div>
    )
};