import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSevenc() {
    console.log("This level just makes me sad.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>First Name Only</p>
                    <p>52.375^, 4.884{">"}</p>
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'anne'
                            hint = {<div>You can google this easily enough. We're stopping off to pay our respects.</div>}
                            nextLevel = '../levels/level7d'
                            />
        </div>
    )
};