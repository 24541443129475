import levelFivebImage from '../../constants/imageFiles/levelFivebImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelFiveb() {
    console.log("The answer's not here. You need to check your sources.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>The puppies cheered her up for now.</p>
                    <p>But we still haven't found the source of the problem.</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelFivebImage}
                        alt="The beginning of a river."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'ice cream'
                            hint = {<div>This really reminds me of the old riddle games.</div>}
                            nextLevel = '../levels/level5c'
                            />
        </div>
    )
};