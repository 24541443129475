import { Box, Button, Grid, TextField } from '@mui/material'
import React, { useState } from "react";
import invertLogo from '../constants/imageFiles/invertLogo.png'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Navigate } from 'react-router-dom';


export default function AnswerBox(props) {

    const correctAnswer = props.correctAnswer;
    const hint = props.hint;
    const nextLevel = props.nextLevel;

    const [answerGuess, setAnswerGuess] = useState("");
    const [responseMessage, setResponseMessage] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [snackbarType, setSnackbarType] =useState(undefined);
    const [nav, setNav] =useState(<div></div>);

    function handleClose (){
        setOpen(false);
        if(snackbarType === 'success'){
            setNav(<Navigate to={nextLevel} />)
        }
      };

    function checkAnswer() {
        if (correctAnswer === "01f78026-275c-4d4a-85c1-117084443717"){
            console.log("test")
            setResponseMessage("Error: a correct answer has not been defined for this level.");
            setSnackbarType("warning");
        }
        else if (answerGuess.trim().toLowerCase() === correctAnswer.toLowerCase()){
            setResponseMessage(<div>Your guess "{answerGuess}" was correct!</div>);
                setSnackbarType('success');
        }
        else {
            setResponseMessage(<div>Your guess "{answerGuess}" was wrong.</div>);
            setSnackbarType('error');
        }
        setOpen(true);
    }


    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
            <Grid item xs={2} onClick={()=>{
                setResponseMessage(hint);
                setSnackbarType('info');
                setOpen(true);
                }} sx={{cursor:'pointer'}}>
            <Grid container alignItems='top' justifyContent="center">
            <p>Need a Hint?</p>
            <Box width="100%"/>
            <img
                        style={{ maxHeight:'100px'}}
                        src={invertLogo}
                        alt="A stylized question mark logo"
                    />
    </Grid>
    </Grid>
                <Grid item xs={5} align="right" >
                    <TextField fullWidth
                        value={answerGuess} label="Answer" onChange={(e) => {
                            setAnswerGuess(e.target.value);
                        }} />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="outlined" align="left" onClick={checkAnswer}>Submit</Button>
                </Grid>
            </Grid>
            <br></br>
            <br></br>
            <Box
              sx={{
                width: "100%",
                position: "relative",
              }}
              >
            <Snackbar 
            open = {open}
            autoHideDuration = {3000}
            onClose = {handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{ width: "100%", position: "absolute" }}
            >
            <MuiAlert onClose={handleClose} severity={snackbarType} sx={{ width:'50%', fontSize: '2rem'}}>
            {responseMessage}
        </MuiAlert>
            </Snackbar>
            </Box>
            <br></br>
            <br></br>
            {nav}
        </div>
    )
};

AnswerBox.defaultProps = {
    correctAnswer: "01f78026-275c-4d4a-85c1-117084443717",
    hint: "This level does not have any hints.",
    nextLevel: '../levelselector'
}