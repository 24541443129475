import levelTwoImage from '../../constants/imageFiles/levelTwoImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwo() {
    console.log("Who doesn't hate mondays?")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>First names only</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwoImage}
                        alt="An orange cat eating lasagna."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'garfield'
                            hint = {<div>The Mama's and the Papa's agree.</div>}
                            nextLevel = '../levels/level2b'
                            />
        </div>
    )
};