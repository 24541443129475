import levelTwofImage from '../../constants/imageFiles/levelTwofImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwof() {
    console.log("You're the one.")
    return (
        <div>


            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwofImage}
                        alt="A tan skinned man with black hair and a striped shirt holding a rubber duck."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'ernie'
                            hint = {<div>BFFs with the Bees guy</div>}
                            nextLevel = '../levels/level2g'
                            />
        </div>
    )
};