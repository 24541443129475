import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';
import { useState, useEffect } from 'react';
import charlie from '../../constants/imageFiles/Charlie.jpg';
import { Navigate } from 'react-router-dom';

export default function LevelFifteen() {
    console.log("I love this dog. He's diabetic and has had cataract surgery, but he really is a good boy.");
    const [answerTimeOutReached, setAnswerTimeoutReached] = useState(0)

   useEffect(()=>{const timer = setTimeout(()=> {
    setAnswerTimeoutReached(1);
}, 20000); return () => clearTimeout(timer);
   });

   if (answerTimeOutReached===0){
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>That was a fun game of Simon. Let's play another!</p>
                    <p>This is my dog Charlie, he is a good boy.</p>
                    <p>Say "Good boy"</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={charlie}
                        alt="One of the best dogs in the world clearly being a good boy."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'Good boy'
                            hint = {<div>Type the words "Good Boy"</div>}
                            nextLevel = '../levels/Level15SimonDidntSay'
                            />
        </div>
    )
   }
   else return <Navigate to='../levels/Level15b'/>
};