import levelSixteeneImage from '../../constants/imageFiles/LevelSixteene.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSixteene() {

    console.log("It's not Holly Berry either.")

    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>You sounded just lovely.</p>
                    <p>Solve this and level 16 is complete!</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSixteeneImage}
                        alt="A christmas tree with the word \'Judy\' written repeatedly in a wavy pattern over the tree."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'judy garland'
                            hint = {<div>It's not Wreath Witherspoon.</div>}
                            nextLevel = '../levels/lastlevel'
                            />
        </div>
    )
};