import { Grid, Typography } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelNine() {
    console.log("I can't code the braille bumps to the screen, I think you can find a decoder")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <Typography fontSize="3rem">⠁⠝⠎⠺⠑⠗⠒⠀⠺⠁⠎⠀⠃⠇⠊⠝⠙</Typography>
                    <Typography fontSize="3rem">⠀⠃⠥⠞⠀⠝⠕⠺⠀⠊⠀⠎⠑⠑</Typography>
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'was blind but now i see'
                            hint = {<div>Hard? It's braillely US Grade 2</div>}
                            nextLevel = '../levels/level10'
                            />
        </div>
    )
};