import levelSixteendImage from '../../constants/imageFiles/LevelSixteend.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSixteend() {
    console.log("Still haven't figured out the pattern?")
    console.log("This is the next line.")
    return (
        <div>
            <br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSixteendImage}
                        alt="Sheet music with lyrics written that say \'rock-a-once-baby\'"
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'once in a lullaby'
                            hint = {<div>....in the once top....</div>}
                            nextLevel = '../levels/level16e'
                            />
        </div>
    )
};