import levelOneImage from '../../constants/imageFiles/levelOneImage.jpg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';
import React from 'react';

export default function LevelOne() {
    console.log("As Yoda would say, \"in front of you, the answer is\"")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>A riddle game, let's begin!</p>
                    <p>Will you scratch your head or rub your chin?</p>
                    <p>Ponder over every clue?</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelOneImage}
                        alt="A forest of trees with a winding path. Text reads The answer is in front of you"
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox
                correctAnswer = 'in front of you'
                hint = {<div>The answer is <b>in front of you</b></div>}
                nextLevel = '../levels/level2'
            />
        </div>
    )
};