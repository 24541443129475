import levelSixImage from '../../constants/imageFiles/levelSixImage.jpg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSix() {
    console.log("Nag a Ram? I think Anna Graham would take offense.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Nag a Ram!</p>
                    <p>A Permanent Whitish Rust</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSixImage}
                        alt="A woman looking sternly at a ram. It's hard to get an AI generator to show nagging."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'triumphant'
                            hint = {<div>You can use an online nag a ram solver.</div>}
                            nextLevel = '../levels/level7'
                            />
        </div>
    )
};