import levelTwocImage from '../../constants/imageFiles/levelTwocImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwoc() {
    console.log("Or is it a Doh-nut?")
    return (
        <div>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwocImage}
                        alt="A bald man holding a donut"
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'homer'
                            hint = {<div>I think there's purple in that donut.</div>}
                            nextLevel = '../levels/level2d'
                            />
        </div>
    )
};