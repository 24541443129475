import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';
import { useState, useEffect } from 'react';
import willow from '../../constants/imageFiles/Willow.jpg';
import { Navigate } from 'react-router-dom';

export default function LevelFifteenc() {
    console.log("Willow was our covid dog. She loves Charlie so much. He tolerates her so well. Willow really is our little attention seeker.");
    const [answerTimeOutReached, setAnswerTimeoutReached] = useState(0)

   useEffect(()=>{const timer = setTimeout(()=> {
    setAnswerTimeoutReached(1);
}, 20000); return () => clearTimeout(timer);
   });

   if (answerTimeOutReached===0){
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                <p>This is Willow.</p>
                <p>She gets incredibly jealous when you pay attention to Charlie but not her.</p>
                    <p>Simon says, say "Good girl"</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={willow}
                        alt="Another one of the best dogs in the world clearly being a good girl."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'Good girl'
                            hint = {<div>Type the words "Good girl"</div>}
                            nextLevel = '../levels/Level15d'
                            />
        </div>
    )
   }
   else return <Navigate to='../levels/Level15SimonSaid'/>
};