import { Grid } from '@mui/material'
import logo from '../../constants/imageFiles/logo.png'
import { useNavigate } from 'react-router-dom';

export default function Navbar(){
    const navigate = useNavigate();
    return(
    <div>
    <Grid container spacing={1} border={1}  borderColor={'grey.700'} sx={{display: "inline-flex",
  gridTemplateColumns: "repeat(3, 1fr)"}}>

    <Grid item xs={7} align="left" onClick={()=> navigate('/')} sx={{cursor:'pointer'}}>
    <img
                        style={{ maxWidth: '100%', maxHeight: '100px' }}
                        src={logo}
                        alt="A logo that reads Baffler: Another Internet Riddle Game"
                    />
    </Grid>

    <Grid item xs={3} sx={{borderLeft: 1, borderRadius: '14px', cursor:'pointer', maxWidth: '100%'}} onClick={()=> navigate('/levelselector')} >
        <p>Level Selector</p>
    </Grid>
    <Grid item xs={2} type='button' onClick={()=> navigate('/about')} sx={{borderLeft: 1, borderRadius: '14px', cursor:'pointer', maxWidth: '100%'}}>
        <p>About</p>
    </Grid>
    </Grid>
    </div>
    )
};