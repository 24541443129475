import levelSixteenbImage from '../../constants/imageFiles/LevelSixteenb.png';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSixteenb() {
    console.log("Just continue singing your song. You sound lovely.")
    console.log("This is the next line...")
    return (
        <div>
            <br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSixteenbImage}
                        alt="Measuring scales in clouds"
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'way up high'
                            hint = {<div>You sound lovely, continue....</div>}
                            nextLevel = '../levels/level16c'
                            />
        </div>
    )
};