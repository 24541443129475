import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSeven() {
    console.log("It's a flag. Name the country")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>173,29,37</p>
                    <p>FFFFFF</p>
                    <p>0.77, 0.47, 0.00, 0.48</p>
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'netherlands'
                            hint = {<div>Like an either without and or, find the neither without the nor.</div>}
                            nextLevel = '../levels/level7b'
                            />
        </div>
    )
};