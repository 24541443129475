import levelTwodImage from '../../constants/imageFiles/levelTwodImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwod() {
    console.log("Where are you?")
    return (
        <div>


            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwodImage}
                        alt="A brown hound dog with a blue collar."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'scooby'
                            hint = {<div>Looking for clues?</div>}
                            nextLevel = '../levels/level2e'
                            />
        </div>
    )
};