import levelFivecImage from '../../constants/imageFiles/levelFivecImage.jpg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelFivec() {
    console.log("Some browsers don't play nicely. You might have better luck finding an alternative if you inspect your elements.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Plunged into darkness</p>
                    <p>Find the alternative</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelFivecImage}
                        alt="The answer is happiness.  I won't put clues here in the future."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'happiness'
                            hint = {<div>If only there was an text alternative to this image.</div>}
                            nextLevel = '../levels/level5d'
                            />
        </div>
    )
};