import levelThirteenImage from '../../constants/imageFiles/levelThirteenImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelThirteen() {
    console.log("Ask Caesar what XIII is in English")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Level XIII</p>
                    <p>Yberz Vcfhz fbzrguvat fbzrguvat V guvax nobhg Ebzr nobhg bapr n jrrx Gur nafjre vf wnfba tenpr</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelThirteenImage}
                        alt="A caesar salad."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'jason grace'
                            hint = {<div>It's a cipher salad</div>}
                            nextLevel = '../levels/level14'
                            />
        </div>
    )
};