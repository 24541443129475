import levelSevendeImage from '../../constants/imageFiles/levelSevendeImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSevend() {
    console.log("I'll play harmonica zoon.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>First names only</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSevendeImage}
                        alt="a building with a tall spire on a canal with boats. The sky is lit but the buildings cast in shaddow."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'rembrandt'
                            hint = {<div>The titans?</div>}
                            nextLevel = '../levels/level7e'
                            />
        </div>
    )
};