import levelSevendeImage from '../../constants/imageFiles/levelSevendeImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSevene() {
    console.log("ICC what you did there.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Now the place</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSevendeImage}
                        alt="a building with a tall spire on a canal with boats. The sky is lit but the buildings cast in shaddow."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'the hague'
                            hint = {<div>Or at least what AI Image generators think the place looks like...</div>}
                            nextLevel = '../levels/level8'
                            />
        </div>
    )
};