import levelSixteenImage from '../../constants/imageFiles/LevelSixteena.png';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSixteen() {
    console.log("It's not a particular where. It's just some where.")
    console.log("Underneath, it's not snowing arrows, it's....")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Where?</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSixteenImage}
                        alt="Storm clouds and precipitation with archery bows."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'somewhere over the rainbow'
                            hint = {<div>It's not snowing arrows, and I'm not asking why</div>}
                            nextLevel = '../levels/level16b'
                            />
        </div>
    )
};