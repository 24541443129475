import levelTwojImage from '../../constants/imageFiles/levelTwojImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwoj() {
    console.log("This level is hard enarf as it is.")
    return (
        <div>


            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwojImage}
                        alt="Two lab mice. The one on the left has arrows pointing to it and is holding some sort of device."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'brain'
                            hint = {<div>The definition of insanity is doing the same thing every night and expecting different results.</div>}
                            nextLevel = '../levels/level2k'
                            />
        </div>
    )
};