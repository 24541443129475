import { Link } from "react-router-dom";

function LastLevel() {
    return (
        <div>
            <br></br>
            This game is still under construction. Come back for more!
            <br></br>
            <Link to='../../levelSelector'>Level Selector</Link>
            <br></br>
            </div>
    )
}

export default LastLevel;