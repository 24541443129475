import levelSevenbImage from '../../constants/imageFiles/levelSevenbImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelSevenb() {
    console.log("It's not a car stop.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>First names only</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelSevenbImage}
                        alt="A windmill and tulips beneath a starry sky, artistic."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'vincent'
                            hint = {<div>Sunflowers were famous even before Post Malone.</div>}
                            nextLevel = '../levels/level7c'
                            />
        </div>
    )
};