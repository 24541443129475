import levelFivedImage from '../../constants/imageFiles/levelFivedImage.jpg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelFived() {
    console.log("I can never remember how to spell it. Earl? Irl? Url?")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>She's happy because she knows you'll win.</p>
                    <p>Is this the last part of level 5?</p>
                    <p>Better peak ahead.</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelFivedImage}
                        alt="A happy woman"
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'victory'
                            hint = {<div>Maybe ask Earl if there's a level 5e</div>}
                            nextLevel = '../levels/level6'
                            />
        </div>
    )
};