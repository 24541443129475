import levelTwelveImage from '../../constants/imageFiles/levelTwelveImage.png';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwelve() {
    console.log("Sorry, I mixed my semaphores.")
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Hey look, they're waving!</p>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwelveImage}
                        alt="A person waving flags."
                    />
                </Grid>
            </Grid>

            <br></br>
            < AnswerBox 
                            correctAnswer = 'star spangled banner'
                            hint = {<div>It's like dancing to YMCA with flags.</div>}
                            nextLevel = '../levels/level13'
                            />
        </div>
    )
};