import levelTwolImage from '../../constants/imageFiles/levelTwolImage.jpeg';
import { Grid } from '@mui/material'
import AnswerBox from '../AnswerBox';

export default function LevelTwol() {
    console.log("The life of the wife was ended by the knife.")

    return (
        <div>


            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={9}>
                    <img
                        style={{ maxWidth: "100%" }}
                        src={levelTwolImage}
                        alt="A baby boy with a yellow shirt and red overalls."
                    />
                </Grid>
            </Grid>
            <br></br>
            < AnswerBox 
                            correctAnswer = 'stewie'
                            hint = {<div>The establishment knows this answer best.</div>}
                            nextLevel = '../levels/level3'
                            />
        </div>
    )
};