export default function About (){
    return(
        <div>
            <h1>About</h1>

            <div class="about">
            <p>Welcome to Baffler: Another Internet Riddle Game. This game is a passion project of mine and a tribute to the 90s-early 2000s era of internet riddle games like Notpron, OddPawn, and the like. It's also a learning exercise for me to practice coding skills in React as well as building and deploying to the web.</p>
            <p>The rules of the game are pretty simple. Figure out the answer, and type it in the box provided. The levels aren't in any particular order. Some might be hard. Others might be easy. Some will be riddles; some will be cryptograms; others will be games where the answer is provided if you win.</p>
            <p>A level selector is provided in the navigation bar. If you get stuck on a level or just don't like it, skip ahead and come back later at your leisure.</p>
            <p>This game is still under develpment. I work on it in my free time, so please check back often for new levels and updates!</p>
            </div>

            <h2>About Me</h2>

            <div class="about">
            <p>I love puzzles and solving problems, and learning to code sparks that passion in me. If you are interested in learning to code, I recommend <a href='https://www.freecodecamp.org/'>FreeCodeCamp.org</a> to everyone looking to get started. It takes you from novice to being able to learn and grow on your own by reading documentation.</p>
            <p>If you're interested in my other projects, check out <a href='https://www.quidquo.org/finance_search/index.html'>QuidQuo.org</a>, a website dedicated to understanding campaign finance in Iowa.</p>
            <p>You can also download my React Native App, SubaFun, in the Google Play Store. This is a game I built as a proof of concept app to demonstrate errorless learning concepts.</p>
            <p>You can see my FreeCodeCamp practice projects at <a href='https://codepen.io/thiggs'>My Codepen</a> and <a href='https://github.com/Thiggs/'>My Github</a>.</p>
            <p>If you're looking for a developer, you can find me on <a href='https://www.linkedin.com/in/tylerhiggsdev/'>LinkedIn</a>.</p>
            </div>
        </div>
    )
};