import { Grid } from '@mui/material'
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export default function LevelFifteen() {
    const [answerTimeOutReached, setAnswerTimeoutReached] = useState(0)

   useEffect(()=>{const timer = setTimeout(()=> {
    setAnswerTimeoutReached(1);
}, 20000); return () => clearTimeout(timer);
   });

   if (answerTimeOutReached===0){
    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <p>Oops. Simon Didn't say.</p>
                    <p>These levels are timed. You have 20 seconds to answer (or not).</p>
                    <p>If Simon doesn't say, wait.</p>

                    <p>Level will restart in 20 seconds.</p>
                    
                </Grid>
            </Grid>

        </div>
    )
   }
   else return <Navigate to='../levels/Level15'/>
};